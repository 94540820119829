import React from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import s from "./ModalAutotranslator.module.css";

const ModalAutotranslator = ({ onClose, refreshCurrentQuestion }) => {
  return (
    <ModalWrapper onClose={onClose}>
      <div className={s.wrapper}>
        <h2 className={s.title}>Выключите автопереводчик !!!</h2>
        <p className={s.text}>
          Потому что программа не сможет корректно проверить Ваше задание.{" "}
        </p>
        {/* <p className={s.text}>
          <a
            href="https://www.youtube.com/watch?v=DNjeGQ3DqKk&feature=youtu.be"
            target="_blank"
            rel="noopener noreferrer"
          >
            Ссылка на видео где рассказывается как отключить автопереводчик
          </a>
        </p> */}
      </div>
    </ModalWrapper>
  );
};

export default ModalAutotranslator;
