export default function blockDefinition(blockHash) {
  let blockIndex = 0;
  if (blockHash === "z01abcd1") {
    blockIndex = 1;
  } else if (blockHash === "01abcde2") {
    blockIndex = 2;
  } else if (blockHash === "1abcdef3") {
    blockIndex = 3;
  } else if (blockHash === "abcdefg4") {
    blockIndex = 4;
  } else if (blockHash === "bcdefgh5") {
    blockIndex = 5;
  } else if (blockHash === "cdefghi6") {
    blockIndex = 6;
  }

  return blockIndex;
}
